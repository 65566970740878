/* xs, extra-small: 0px or larger
sm, small: 600px or larger
md, medium: 960px or larger
lg, large: 1280px or larger
xl, extra-large: 1920px or larger */

.visible-sm-down, .visible-md-down, .visible-lg-down {
    display: none !important;
}

@media only screen and (max-width: 600px) {
    .hidden-sm-down {
        display: none !important;
    }
    .visible-sm-down {
        display: block !important;
    }

    td, th {
        padding: 8px !important;
    }
}

@media only screen and (max-width: 960px) {
    .hidden-md-down {
        display: none !important;
    }
    .visible-md-down {
        display: block !important;
    }
}

@media only screen and (max-width: 1280px) {
    .hidden-lg-down {
        display: none !important;
    }
    .visible-lg-down {
        display: block !important;
    }
}

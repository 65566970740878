/*html::after {
  content: "";
  background: url(background.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  opacity: 0.1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}*/
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

body:not(.login) {
    /* background: url(header-bg.png); */
    background-position: top left;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% auto;
    background-color: #fff;
}

body.login {
    background: url(vaaka_pitka_RGB.png) no-repeat;
    background-position: left 70%;
    background-attachment: fixed;
    background-size: 85vw;
    background-color: #fff;
}

label {
    color: rgb(64,64,64) !important;
    text-shadow: 0 0 5px rgba(255,255,255,1);
}

.white-glow {
    box-shadow: 0 0 15px rgba(255,255,255,0.8);
    background-color: rgba(255,255,255,0.4) !important;
    border-radius: 10px;
}

.white-glow-parent > div {
    box-shadow: 0 0 15px rgba(255,255,255,0.8);
    background-color: rgba(255,255,255,0.4) !important;
    border-radius: 10px;
    padding: 5px !important;
}
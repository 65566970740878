@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
.Aligner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 24em;
  height: 50vh;
  margin-top: 10vh;
  margin-bottom: 100px;
}

.Aligner-item {
  flex: 1;
  max-width: 800px;
}

.Aligner-item--top {
  align-self: flex-start;
}

.Aligner-item--bottom {
  align-self: flex-end;
}

.App {
  text-align: center;
}

.App-logo {
  height: 85px;
}

.App-logo-smaller {
  height: 65px;
}


.App-header {
  height: 200px;
  padding: 20px;
  color: #34495e;
  text-transform: uppercase;
}

.App-intro {
  font-size: large;
}

.App-button {
  width: 400px;
}

.language-selector {
  margin: auto;
  padding-left: 10px;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.pagination-container {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  margin: auto;
  width: max-content;
}

.pagination {
  display: flex;
  border:1px solid #edf2f9;
  padding: 0;
}

.pagination-li {
  width: 40px;
  height: 40px;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.pagination-li-active {
  border:1px solid #000000;
  border-radius: 50%;
}

.pagination-a {
  color:#000000;
  padding: 10px 7px;
  list-style-type: none;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
}

.pagination-a-active {
  color: #000000;
}

.page-size-select {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  align-items: center;
  margin-left: 1rem;
  border: 1px solid #eff0f3;
  border-radius: 5px;
  height: 40px;
  padding: 0 10px;
  align-self: center;
  background-color: #ffffff;
}

.page-size-text {
  justify-self: center;
  font-family: 'Roboto', sans-serif;
}

.search-label{
  height: 72px;
  position: relative;
  top: -14px;
}

.search-box{
  border: 2px red solid;
  background-color: rgba(255, 255, 255, 0.3);
}

.search-box-empty{
  border: 2px lightgray solid;
  background-color: rgba(255, 255, 255, 0.3);
}

.language-select-button {
  color: #ffffff !important;
  border: 0 !important;
  font-size: 0.85rem !important;
  text-transform: uppercase;
}